import React from "react";

export class ConfidentielRule extends React.Component<any, any> {
    render() {
        return (
            <div>
                <h1>Règles de Confidentialité Application Mobile Google Play & AppStore</h1>

                <h2>1 - L'application mobile Sharing Shopping disponible sur les Google Play.</h2>

                <p>
                    En téléchargeant l'application Sharing Shopping, vous acceptez les conditions relatives à cette application mobile notamment
                    la permission d'accéder aux différentes fonctionnalités de l'appareil Android supportant l'application.
                    Ces permissions sont les suivantes :
                    <ul>
                        <li>Permission d'accéder à internet</li>
                    </ul>

                    D'autre part, les informations personnelles que vous auriez écrit dans l'application seront stockés sur les serveurs de l'application
                </p>
            </div>
        )
    }
}